import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NModal"], {
    class: "add-3d-files-modal main-modal",
    preset: "dialog",
    "show-icon": false,
    title: `Add 3D ${$props.singleFile ? 'file' : 'files'}`
  }, {
    default: _withCtx(() => [
      _createVNode($setup["Dropzone"], {
        drawingsAllowed: false,
        rfqListsAllowed: false,
        singleFile: $props.singleFile,
        onFilesPicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('extra3DFilesPicked', $event)))
      }, null, 8, ["singleFile"])
    ]),
    _: 1
  }, 8, ["title"]))
}