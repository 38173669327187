import { ref } from "vue";
import drawingFileFormats from "@/constants/drawingFileFormats";
import rfqListFileFormats from "@/constants/rfqListFileFormats";
import threeDFormatValidationRegex from "@/regex/threeDFormatValidationRegex";

export default () => {
  const threeDFiles = ref([] as File[]);
  const pdfFiles = ref([] as File[]);
  const rfqFiles = ref([] as File[]);

  const addNewFilesToCollections = (files: File[], skipRfqLists = false) => {
    let need3DFilesSorting = false;
    files.forEach((file) => {
      if (threeDFormatValidationRegex.test(file.name)) {
        need3DFilesSorting = true;
        threeDFiles.value.push(file);
      } else if (drawingFileFormats.includes(file.type)) {
        pdfFiles.value.push(file);
      } else if (!skipRfqLists && rfqListFileFormats.includes(file.type)) {
        rfqFiles.value.push(file);
      }
    });
    if (need3DFilesSorting) {
      threeDFiles.value.sort((a, b) => a.name.localeCompare(b.name));
    }
  };

  return {
    threeDFiles,
    pdfFiles,
    rfqFiles,
    addNewFilesToCollections,
  };
};
