import { toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NDropdown"], {
    class: "match-columns-table-dropdown",
    show: $setup.showDropdown,
    "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => (($setup.showDropdown) = $event)),
    options: $setup.options,
    placement: "bottom-start",
    trigger: "click"
  }, {
    default: _withCtx(() => [
      _createVNode($setup["NButton"], {
        type: "info",
        ghost: "",
        round: "",
        onClick: _cache[0] || (_cache[0] = () => {})
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($setup.activeOption ? $setup.activeOption.label : "Select"), 1),
          _createVNode($setup["ChevronDown"], {
            color: $setup.colors.buttonInfoColor,
            size: 9
          }, null, 8, ["color"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["show", "options"]))
}