import { FileEntityInterface } from "@/types/FileEntity";

export interface ModelEntityInterface extends FileEntityInterface {
  convertedUploadId: {
    id: string;
    filename: string;
    link: string;
    status: string;
  };
  previewUploadId: {
    id: string;
    filename: string;
    link: string;
    status: string;
  };
}

export class ModelEntityClass implements ModelEntityInterface {
  id = "";
  rfqUploadId = "";
  originalUploadId = {
    id: "",
    filename: "",
    status: "",
    link: "",
  };
  convertedUploadId = {
    id: "",
    filename: "",
    link: "",
    status: "",
  };
  previewUploadId = {
    id: "",
    filename: "",
    link: "",
    status: "",
  };
  userId = {
    id: "",
    firstName: "",
    lastName: "",
  };
  createdAt = "";
  updatedAt = "";
}
