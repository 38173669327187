import { ref } from "vue";
import { UploadStatuses } from "@/enums/UploadStatuses";
import { FileUploadProcessingStatuses } from "@/enums/FileUploadProcessingStatuses";
import { ModelEntityInterface } from "@/types/ModelEntity";

interface PreviewableObject {
  modelId: {
    fileId: ModelEntityInterface;
  };
  fileId: ModelEntityInterface;
  modelStatus: UploadStatuses;
  status: FileUploadProcessingStatuses;
}

export default () => {
  const showPartModelViewer = ref(false);
  const partModelViewIndex = ref(-1);

  const onModelPreviewClicked = (part: PreviewableObject, index: number) => {
    if (isPreviewAvailable(part)) {
      partModelViewIndex.value = index;
      showPartModelViewer.value = true;
    }
  };

  const isPreviewAvailable = (
    obj: PreviewableObject,
    checkUploadStatus = false
  ) => {
    if (!obj.modelId?.fileId && !obj.fileId) {
      return false;
    }
    const isPart = !!obj.modelId;
    const { convertedUploadId } = obj.modelId ? obj.modelId.fileId : obj.fileId;
    const isUploadStatusValid =
      !checkUploadStatus ||
      isPreviewablePart(isPart, obj) ||
      isPreviewableAdditionalFile(isPart, obj);
    return !!(
      convertedUploadId &&
      convertedUploadId.filename.endsWith(".zip") &&
      isUploadStatusValid
    );
  };

  const isPreviewablePart = (isPart: boolean, obj: PreviewableObject) => {
    return (
      isPart &&
      obj.modelStatus === UploadStatuses.Finished &&
      obj.modelId.fileId.convertedUploadId.link
    );
  };

  const isPreviewableAdditionalFile = (
    isPart: boolean,
    obj: PreviewableObject
  ) => {
    return (
      !isPart &&
      obj.fileId.previewUploadId.status === FileUploadProcessingStatuses.Done &&
      obj.fileId.convertedUploadId.link
    );
  };

  const onPartModelViewerClose = () => {
    showPartModelViewer.value = false;
    partModelViewIndex.value = -1;
  };

  return {
    showPartModelViewer,
    partModelViewIndex,
    onModelPreviewClicked,
    isPreviewAvailable,
    onPartModelViewerClose,
  };
};
