import cadex from '@cadexchanger/web-toolkit';

export class ProgressStatusManager extends cadex.Base_ProgressStatusObserver {
  /**
   * @param {HTMLElement} theContainer
   */
  constructor(theContainer) {
    super();

    this.myProgressIndicator = document.createElement('div');
    this.myProgressIndicator.id = 'progress';
    this.myProgressIndicator.classList.add('progress');
    theContainer.appendChild(this.myProgressIndicator);

    this.myProgressBar = document.createElement('div');
    this.myProgressBar.classList.add('progress-bar');
    this.myProgressBar.setAttribute('role', 'progressbar');
    this.myProgressBar.setAttribute('aria-valuenow', '0');
    this.myProgressBar.style.width = '0%';
    this.myProgressBar.textContent = '0%';
    this.myProgressIndicator.appendChild(this.myProgressBar);
  }

  /**
   * @returns {cadex.Base_ProgressStatus}
   */
  init() {
    if (!this.myProgressIndicator) {
      return new cadex.Base_ProgressStatus();
    }
    if (this.myProgressStatus) {
      this.myProgressStatus.cancel();
      this.myProgressStatus.unregister(this);
    }
    this.myProgressStatus = new cadex.Base_ProgressStatus();
    this.myProgressStatus.register(this);
    this.myProgressIndicator.style.visibility = 'visible';
    return this.myProgressStatus;
  }

  /**
   * @override
   * @param {!cadex.Base_ProgressStatus} theStatus
   */
  changedValue(theStatus) {
    const aValue = `${Math.floor(theStatus.value)}%`;
    const aProgressBar = /** @type {!HTMLDivElement} */(this.myProgressBar);
    aProgressBar.style.width = aValue;
    aProgressBar.textContent = aValue;
    aProgressBar.setAttribute('aria-valuenow', `${Math.round(theStatus.value)}`);
  }

  /**
   * @override
   * @param {!cadex.Base_ProgressStatus} theStatus
   */
  completed(theStatus) {
    const aValue = `${Math.floor(theStatus.value)}%`;
    const aProgressBar = /** @type {!HTMLDivElement} */(this.myProgressBar);
    aProgressBar.style.width = aValue;
    aProgressBar.textContent = aValue;
    aProgressBar.setAttribute('aria-valuenow', `${Math.round(theStatus.value)}`);
    // hide progress status with delay
    setTimeout(() => {
      /** @type {!HTMLDivElement} */(this.myProgressIndicator).style.visibility = 'hidden';
      aProgressBar.style.width = '0%';
    }, 1000);
  }

  /**
   * @override
   */
  canceled() {
    // hide progress status with delay
    setTimeout(() => {
      /** @type {!HTMLDivElement} */(this.myProgressIndicator).style.visibility = 'hidden';
      /** @type {!HTMLDivElement} */(this.myProgressBar).style.width = '0%';
    }, 1000);
  }
}
