export interface FileEntityInterface {
  id: string;
  rfqUploadId: string;
  originalUploadId: {
    id: string;
    filename: string;
    status: string;
    link: string;
  };
  userId: {
    id: string;
    firstName: string;
    lastName: string;
  };
  createdAt: string;
  updatedAt: string;
}

export class FileEntityClass implements FileEntityInterface {
  id = "";
  rfqUploadId = "";
  originalUploadId = {
    id: "",
    filename: "",
    status: "",
    link: "",
  };
  userId = {
    id: "",
    firstName: "",
    lastName: "",
  };
  createdAt = "";
  updatedAt = "";
}
