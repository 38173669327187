import { Ref } from "vue";
import nameMatchingFileMethods from "@/composables/nameMatchingFileMethods";
import { AdditionalFileType } from "@/enums/AdditionalFileType";
import { UploadStatuses } from "@/enums/UploadStatuses";
import threeDFormatValidationRegex from "@/regex/threeDFormatValidationRegex";
import AdditionalThreeDFile from "@/types/request-for-quote/AdditionalThreeDFile";
import AdditionalDrawingFile from "@/types/request-for-quote/AdditionalDrawingFile";
import { FileEntityInterface } from "@/types/FileEntity";
import { ModelEntityInterface } from "@/types/ModelEntity";

export default (
  modelAdditionalList: Ref<AdditionalThreeDFile[]>,
  drawingAdditionalList: Ref<AdditionalDrawingFile[]>,
  additionalFiles: Ref<(AdditionalThreeDFile | AdditionalDrawingFile)[]>,
  additionalFilesLoadingIndexes: Ref<Set<number>>
) => {
  const { getAdditionalFileName } = nameMatchingFileMethods();

  const setAdditionalFilesFromQuote = (
    files: { id: string; fileId: FileEntityInterface | ModelEntityInterface }[]
  ) => {
    const models: AdditionalThreeDFile[] = [];
    const drawings: AdditionalDrawingFile[] = [];
    files?.forEach((file) => {
      if (
        threeDFormatValidationRegex.test(file.fileId.originalUploadId.filename)
      ) {
        models.push({
          id: file.id,
          fileId: file.fileId as ModelEntityInterface,
          file: undefined,
          type: AdditionalFileType.Model,
          status: UploadStatuses.Finished,
        });
      } else {
        drawings.push({
          id: file.id,
          fileId: file.fileId as FileEntityInterface,
          file: undefined,
          type: AdditionalFileType.Drawing,
          status: UploadStatuses.Finished,
        });
      }
    });
    modelAdditionalList.value.push(...models);
    drawingAdditionalList.value.push(...drawings);
    additionalFiles.value = [
      ...modelAdditionalList.value,
      ...drawingAdditionalList.value,
    ];
    additionalFiles.value.sort(sortAdditionalFiles);
  };
  const sortAdditionalFiles = (
    a: AdditionalDrawingFile | AdditionalThreeDFile,
    b: AdditionalDrawingFile | AdditionalThreeDFile
  ) => {
    const aName = getAdditionalFileName(a);
    const bName = getAdditionalFileName(b);
    const aIsModel =
      (a.type && a.type === AdditionalFileType.Model) ||
      threeDFormatValidationRegex.test(aName);
    const bIsModel =
      (b.type && b.type === AdditionalFileType.Model) ||
      threeDFormatValidationRegex.test(bName);

    if (aIsModel && !bIsModel) {
      return -1;
    }
    if (bIsModel && !aIsModel) {
      return 1;
    }
    const indexA = aName.lastIndexOf(".");
    const nameA = aName.slice(0, indexA);
    const extensionA = aName.slice(indexA);

    const indexB = bName.lastIndexOf(".");
    const nameB = bName.slice(0, indexB);
    const extensionB = bName.slice(indexB);

    const nameCompare = nameA.localeCompare(nameB);
    if (nameCompare !== 0) {
      return nameCompare;
    }
    return extensionA.localeCompare(extensionB);
  };

  return {
    modelAdditionalList,
    drawingAdditionalList,
    additionalFiles,
    additionalFilesLoadingIndexes,
    setAdditionalFilesFromQuote,
    sortAdditionalFiles,
  };
};
