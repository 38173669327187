import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "description" }
const _hoisted_2 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NModal"], {
    class: "notice-message-modal",
    preset: "dialog",
    "show-icon": false,
    title: "Please notice"
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString($setup.descriptionMessage), 1),
      _createElementVNode("div", _hoisted_2, [
        _createVNode($setup["NButton"], {
          class: "medium-button n-button-save",
          type: "info",
          round: "",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('showModal')))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Continue")
          ]),
          _: 1
        }),
        _createVNode($setup["NButton"], {
          class: "medium-button n-button-cancel",
          type: "tertiary",
          round: "",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Cancel")
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}