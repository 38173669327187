import { nextTick, Ref } from "vue";
import { UploadStatuses } from "@/enums/UploadStatuses";
import { FileEntityInterface } from "@/types/FileEntity";
import { ModelEntityInterface } from "@/types/ModelEntity";
import FileForUploadInterface from "@/types/FileForUploadInterface";
import RfqPartInterface from "@/types/request-for-quote/RfqPartInterface";

export default (
  filesForUpload: Ref<FileForUploadInterface[]>,
  partList: Ref<RfqPartInterface[]>
) => {
  const preparePartFilesForUpload = (quoteId: string) => {
    const allFilesForUpload = [] as FileForUploadInterface[];
    partList.value.forEach((part) => {
      if (
        part.model &&
        (part.modelStatus === UploadStatuses.Pending ||
          part.modelStatus === UploadStatuses.Empty)
      ) {
        allFilesForUpload.push({
          file: part.model as File,
          rfqId: quoteId,
          partId: part.id,
          uploadStatus: UploadStatuses.Pending,
        });
      }
      if (
        part.drawing &&
        (part.drawingStatus === UploadStatuses.Pending ||
          part.drawingStatus === UploadStatuses.Empty)
      ) {
        allFilesForUpload.push({
          file: part.drawing as File,
          rfqId: quoteId,
          partId: part.id,
          uploadStatus: UploadStatuses.Pending,
        });
      }
    });
    setFilesToUpload(allFilesForUpload);
  };

  const setFilesToUpload = (allFilesForUpload: FileForUploadInterface[]) => {
    if (
      !filesForUpload.value.length ||
      filesForUpload.value.some(
        (item) =>
          item.uploadStatus !== UploadStatuses.Finished &&
          item.uploadStatus !== UploadStatuses.Error
      )
    ) {
      nextTick(() => {
        filesForUpload.value.push(...allFilesForUpload);
      });
    } else {
      filesForUpload.value = [];
      nextTick(() => {
        filesForUpload.value = allFilesForUpload;
      });
    }
  };
  const onSetToLoad = (file: FileForUploadInterface) => {
    const item = filesForUpload.value.find((el) => el === file);
    if (item) {
      item.uploadStatus = UploadStatuses.Loading;
    }
  };
  const onFinishedLoadingFile = (fileName: string) => {
    const fileForUpload = filesForUpload.value.find(
      (item) =>
        item.file.name === fileName &&
        item.uploadStatus !== UploadStatuses.Finished
    );
    if (fileForUpload) {
      fileForUpload.uploadStatus = UploadStatuses.Finished;
    }
  };

  const onChangedStatus = (
    fileName: string,
    status: UploadStatuses,
    fileId?: FileEntityInterface | ModelEntityInterface
  ): boolean => {
    let index = partList.value.findIndex(
      (file) => file.drawing?.name === fileName
    );
    if (index !== -1) {
      partList.value[index].drawingStatus = status;
      if (fileId) {
        partList.value[index].drawingId.fileId = fileId;
      }
      return true;
    }
    index = partList.value.findIndex((file) => file.model?.name === fileName);
    if (index !== -1) {
      partList.value[index].modelStatus = status;
      if (fileId) {
        partList.value[index].modelId.fileId = fileId as ModelEntityInterface;
      }
      return true;
    }
    return false;
  };

  const updateModelFileId = (
    currentFileId: ModelEntityInterface,
    newFileId: ModelEntityInterface
  ) => {
    const { convertedUploadId, previewUploadId } = newFileId;
    currentFileId.convertedUploadId = convertedUploadId;
    currentFileId.previewUploadId = previewUploadId;
  };

  return {
    preparePartFilesForUpload,
    setFilesToUpload,
    onSetToLoad,
    onFinishedLoadingFile,
    onChangedStatus,
    updateModelFileId,
  };
};
