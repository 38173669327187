import { Ref } from "vue";
import nameMatchingFileMethods from "@/composables/nameMatchingFileMethods";
import { UploadStatuses } from "@/enums/UploadStatuses";
import RfqPartInterface from "@/types/request-for-quote/RfqPartInterface";
import { ModelEntityClass } from "@/types/ModelEntity";
import { FileEntityClass } from "@/types/FileEntity";

export default (
  threeDFiles: Ref<File[]>,
  pdfFiles: Ref<File[]>,
  partList: Ref<RfqPartInterface[]>
) => {
  const { getFileNameWithoutExtension } = nameMatchingFileMethods();

  const setPartListWithoutRfqList = () => {
    threeDFiles.value.forEach((file) => {
      const fileName = getFileNameWithoutExtension(file.name);
      if (
        !partList.value.some((part) => part.name.toLowerCase() === fileName)
      ) {
        const matchingDrawingFile = pdfFiles.value.find(
          (drawing) => fileName === getFileNameWithoutExtension(drawing.name)
        );
        partList.value.push(
          getNewPartListItem(file, matchingDrawingFile, fileName)
        );
      }
    });
  };

  const getNewPartListItem = (
    modelFile: File,
    matchingDrawingFile: File | undefined,
    fileName: string
  ) => {
    return {
      id: "",
      name: fileName.toUpperCase(),
      model: modelFile,
      drawing: matchingDrawingFile,
      quantities: [],
      remarks: [],
      status: -1,
      modelStatus: UploadStatuses.Pending,
      drawingStatus: matchingDrawingFile
        ? UploadStatuses.Pending
        : UploadStatuses.Empty,
      customerPNRev: "",
      modelId: {
        id: "",
        fileId: new ModelEntityClass(),
      },
      drawingId: {
        id: "",
        fileId: new FileEntityClass(),
      },
    };
  };

  return {
    setPartListWithoutRfqList,
  };
};
