import MatchNameSortedFile from "@/types/request-for-quote/MatchNameSortedFile";
import { FileNameMatchLevel } from "@/enums/FileNameMatchLevel";
import AdditionalThreeDFile from "@/types/request-for-quote/AdditionalThreeDFile";
import AdditionalDrawingFile from "@/types/request-for-quote/AdditionalDrawingFile";

export default () => {
  const getAdditionalData = (
    partName: string,
    fileName: string,
    modelName: string
  ) => {
    if (fileName === partName || fileName === modelName) {
      return {
        matchLevel: FileNameMatchLevel.Full,
        class: "full",
      };
    }
    if (
      fileName.startsWith(partName) ||
      (modelName && fileName.startsWith(modelName))
    ) {
      return {
        matchLevel: FileNameMatchLevel.Partial,
        class: "partial",
      };
    }
    return {
      matchLevel: FileNameMatchLevel.NoMatch,
      class: "no-match",
    };
  };

  const getFileNameWithoutExtension = (name: string) => {
    return name.replace(/\.[^/.]+$/, "").toLowerCase();
  };

  const getFileExtension = (fileName: string) => {
    return fileName.substring(fileName.lastIndexOf(".") + 1);
  };

  const compareAdditionalFiles = (
    a: MatchNameSortedFile,
    b: MatchNameSortedFile
  ) => {
    if (a.matchLevel > b.matchLevel) {
      return -1;
    }
    if (b.matchLevel > a.matchLevel) {
      return 1;
    }
    return a.name.localeCompare(b.name);
  };

  const getAdditionalFileName = (
    file: AdditionalThreeDFile | AdditionalDrawingFile | File
  ) => {
    if (file instanceof File) {
      return file.name.toLowerCase();
    }
    return (
      file.file ? file.file.name : file.fileId.originalUploadId.filename
    ).toLowerCase();
  };

  return {
    getAdditionalData,
    getFileNameWithoutExtension,
    getFileExtension,
    compareAdditionalFiles,
    getAdditionalFileName,
  };
};
