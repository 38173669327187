import cadex from '@cadexchanger/web-toolkit';

class CancellationObserver extends cadex.Base_ProgressStatusObserver {
  /**
   * @param {XMLHttpRequest} xhr
   */
  constructor(xhr) {
    super();
    this.xhr = xhr;
  }

  /**
   * @override
   */
  changedValue() { /**/ }

  /**
   * @override
   */
  completed() { /**/ }

  /**
   * @override
   */
  canceled() {
    this.xhr.abort();
  }
}

/**
 * Remote file data provider.
 * @param {string} theUrl
 * @param {cadex.Base_ProgressScope} theProgressScope
 * @returns {Promise<ArrayBuffer>}
 */
export const fetchFile = async (theUrl, theProgressScope) => {
  const aFileDownloadingScope = new cadex.Base_ProgressScope(theProgressScope);
  /** @type {CancellationObserver|undefined} */
  let aProgressStatusCancellationObserver;
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'arraybuffer';
    xhr.open('GET', theUrl, true);
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);
      } else {
        reject(new Error(xhr.statusText));
      }
    };
    xhr.onerror = () => {
      reject(new Error(xhr.statusText));
    };
    xhr.onprogress = (event) => {
      aFileDownloadingScope.increment(event.loaded - aFileDownloadingScope.value);
    };
    xhr.onreadystatechange = () => {
      if (xhr.readyState === xhr.HEADERS_RECEIVED && xhr.status === 200) {
        const fileSize = xhr.getResponseHeader('content-length');
        aFileDownloadingScope.setRange(0, Number(fileSize));
      }
    };

    aProgressStatusCancellationObserver = new CancellationObserver(xhr);
    aFileDownloadingScope.owner.register(aProgressStatusCancellationObserver);

    xhr.send();
  })
    .finally(() => {
      aFileDownloadingScope.close();
      if (aProgressStatusCancellationObserver) {
        aFileDownloadingScope.owner.unregister(aProgressStatusCancellationObserver);
      }
    });
}

export class ModelAnalyzer extends cadex.ModelData_SceneGraphElementVoidVisitor {
  constructor() {
    super();
    this.hasBRepRep = false;
    this.polyRepCount = 0;
  }

  /**
   * @param {cadex.ModelData_Part} thePart
   */
  visitPart(thePart) {
    const aHasBRepRep = Boolean(thePart.brepRepresentation());
    if (aHasBRepRep) {
      this.hasBRepRep = true;
    }
    this.polyRepCount = Math.max(this.polyRepCount, thePart.numberOfRepresentation - (aHasBRepRep ? 1 : 0));
  }
}
